body {
  background: #21262e !important;
}

html,
body {
  padding: 0;
  margin: 0;
}

.css-1csqiwz-MuiPaper-root,
.css-3vyd2d-MuiPaper-root,
.css-1594gl9-MuiPaper-root,
.css-1ps6pg7-MuiPaper-root,
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper,
.css-mkzahr-MuiListSubheader-root {
  background: #2c323d !important;
  border-radius: 12px !important;
  border: none !important;
}

.css-14v3fac-MuiToolbar-root,
.css-fxbtpg {
  background-color: #21262e !important;
}

.css-14v3fac-MuiToolbar-root {
  max-height: 64px;
}

.css-n7rtur-MuiPaper-root-MuiAppBar-root,
.css-o5srbh-MuiPaper-root-MuiAppBar-root {
  background-color: rgba(31, 36, 43, 0.65) !important;
}

.new-project-btn-div {
  display: flex;
  align-self: flex-end;
  margin: auto;
}

.new-project-btn {
  border: none;
  background: linear-gradient(102.31deg, #c41762 -59.96%, #582b7a 138.26%);
  height: 40px;
  border-radius: 8px;
  color: white;
  margin-top: 200px;
  padding-left: 15px;
  padding-right: 15px;
  transition: all 0.2s ease-in-out;
}

.new-project-btn:hover {
  transform: scale(1.1);
}

.new-project-form-btns {
  margin-top: 50px !important;
  margin: auto;
}

.form {
  margin: auto;
}

.input {
  height: 30px;
}

.inputs-div {
  display: flex;
  flex-direction: column;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 50px !important;
}

.width-100 {
  max-width: 70px !important;
  height: 100px;
}

a,
p,
span,
h1,
h2 {
  text-decoration: none !important;
  font-family: "Josefin Sans", sans-serif;
}

h1,
h2 {
  color: white;
}

.logo {
  max-width: 164px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  height: auto;
}

.css-1p823my-MuiListItem-root {
  justify-content: center !important;
}

.languages-h1 {
  color: white;
  transition: all 0.5s ease;
}

.languages-h1:hover {
  color: rgb(165, 165, 165);
  transform: scale(0.9);
}

.btn {
  background: #1f242b;
  border: 1px solid #2c323d;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  position: relative;
  -webkit-backface-visibility: hidden;
  z-index: 1;
}

.active-btn {
  background-image: linear-gradient(
    102.31deg,
    #c41762 -59.96%,
    #582b7a 138.26%
  );
  border: 1px solid #2c323d;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  -webkit-backface-visibility: hidden;
  z-index: 1;
}

.btn-new-project {
  color: white;
  width: 100px;
  height: 50px;
}

.btn-add-project {
  width: 170px;
  height: 50px;
}

.btn span {
  font-size: 17px;
  position: relative;
  z-index: 3;
}

.btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    102.31deg,
    #c41762 -59.96%,
    #582b7a 138.26%
  );
  transition: opacity 0.5s ease-out;
  z-index: 2;
  opacity: 0;
}

.btn:hover:after {
  opacity: 1;
}

.btn span {
  position: relative;
  z-index: 3;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(77, 75, 75, 0.87) !important;
}

.save-btn-div {
  display: flex;
  align-items: center;
  margin-top: 40px;
  justify-content: center;
}

.disabled {
  background: #1f242b;
  border: 1px solid #2c323d;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  position: relative;
  -webkit-backface-visibility: hidden;
  z-index: 1;
}

.save-btn {
  width: 80px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.css-1f2kw6w-MuiContainer-root {
  max-width: 100% !important;
  margin-left: 50px !important;
  width: auto !important;
}

.lang-btns-div {
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-left: 50px;
}

.auth-page-logo {
  max-width: 164px;
  width: 100%;
  height: auto;
  margin-bottom: 56px;
}

.auth-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 150px;
  align-items: center;
}

.login-heading {
  margin-bottom: 60px;
  font-size: 16px;
  line-height: 20px;
}

.login-inputs-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-input {
  color: white;
  height: 56px;
  width: 366px;
  margin-bottom: 28px;
  background: #252a33;
  border: 1px solid #4b515c;
  box-sizing: border-box;
  border-radius: 12px;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
}

.login-input:-webkit-autofill,
.login-input:-webkit-autofill:hover,
.login-input:-webkit-autofill:focus,
.login-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #252a33 inset !important;
}

input:focus {
  outline: transparent;
}

.login-btn {
  width: 368px;
  height: 56px;
  border-radius: 12px;
}

.btn,
.active-btn:hover {
  cursor: pointer;
}

.css-10hburv-MuiTypography-root {
  color: white !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.project-img {
  width: 100%;
  border-radius: 12px;
  height: auto;
  margin: auto;
}

.project-title {
  text-align: center;
}

@media (min-width: 600px) {
  .css-1f2kw6w-MuiContainer-root {
    padding-right: 100px !important;
  }
}
.MuiDataGrid-root,
MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-cell--textLeft {
  color: white !important;
}

.loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}

.project-container {
  margin-top: 64px;
}

.projects-h1 {
  top: 0;
}

.cursor-disabled {
  cursor: not-allowed;
}

.edit-btn:hover {
  cursor: pointer;
}

.css-1t29gy6-MuiToolbar-root {
  display: none !important;
}

.search-and-filter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 40px; */
  /* background-color: #2c323d; */
}

.search-input {
  width: 50%;
  height: 40px;
  border-radius: 12px;
  background: #252a33;
  border: 1px solid #4b515c;
  color: white;
  margin-bottom: 30px;
  padding-left: 10px;
}

.show-missing-translations {
  border-radius: 12px;
  border: none;
  height: 40px;
  padding: 0 30px;
  opacity: 100;
  cursor: pointer;
}

.table-column-thead {
  padding-bottom: 20px;
}

.table-container {
  background-color: #2c323d;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  border-radius: 12px;
}

.css-1f2kw6w-MuiContainer-root {
  margin-left: 32px !important;
}

@media (min-width: 600px) {
  .css-1f2kw6w-MuiContainer-root {
    padding-right: 32px !important;
  }
}

.save-text-white {
  color: white;
  font-size: 17px;
}

.save-text-disabled {
  color: white;
  font-size: 17px;
  opacity: 0.1;
}

.table-p-light {
  color: white;
}

.thead-p {
  text-align: center;
  margin: auto;
}

.table-horizontal-border {
  border-bottom: 1px solid rgb(95, 95, 95);
  padding: 1px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 99%;
  display: block;
}

textarea:focus {
  outline: none !important;

  box-shadow: 0 0 10px #22163d;
}

textarea {
  margin-top: 10px;
  padding: 15px;
}

.css-1l8j5k8 {
  background-color: #2c323d !important;
}

.border-gradient {
  background-color: #2c323d;
  color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px solid rgb(25, 25, 54);
}

p {
  word-break: break-all;
  white-space: normal;
}

.MuiTextField-root {
  border-radius: 12px;
}

.MuiFilledInput-root {
  border-radius: 12px !important;
  background: rgba(37, 42, 51, 0.65) !important;
}

.MuiFilledInput-input {
  color: rgba(255, 255, 255, 0.42) !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input:-webkit-autofill {
  border-radius: 12px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  color: rgba(255, 255, 255, 0.42) !important;
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.42) !important;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.container {
  padding: 30px 20px 0px 50px;
}

.fixed-content {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: black;
  z-index: 999;
}

.relative {
  position: relative;
}
